module.exports = {
  // pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  // title: 'Made Mistakes', // Navigation and site title
  // titleAlt: 'Made Mistakes', // Title for schema.org JSONLD
  // description: 'Personal website of designer Michael Rose (@mmistakes).',
  // url: 'https://wordsto.me', // Domain of your site. No trailing slash!
  // siteLanguage: 'en', // Language Tag on <html> element
  // image: {
  //   // Used for SEO, relative to /static/ folder
  //   src: '/images/made-mistakes-logo.png',
  //   width: 384,
  //   height: 384,
  // },
  // ogLanguage: 'en_US', // Facebook Language
  // googleAnalyticsID: '',
  staticmanApi:
    "https://comment-worker.8c97d9b3-3b3c-491a-aae1-a042a2588f46.workers.dev/api/handle/form",

  // JSONLD / Manifest
  // favicon: '/images/made-mistakes-logo.png', // Used for manifest favicon generation
  // shortName: 'Made Mistakes', // shortname for manifest. MUST be shorter than 12 characters
  // author: {
  //   // Author for schema.org JSONLD
  //   name: 'Michael Rose',
  //   url: 'https://mademistakes.com',
  // },
  // themeColor: '#ffffff',
  // backgroundColor: '#111111',

  // twitter: '@mmistakes', // Twitter username
  // twitterUrl: 'https://twitter.com/mmistakes',
  // facebook: 'Made Mistakes', // Facebook site name
  // githubUrl: 'https://github.com/mmistakes',
  // instagramUrl: 'https://www.instagram.com/mmistakes/',
  // feedUrl: '/atom.xml',
}
